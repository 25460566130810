// @ts-nocheck
import React from 'react';
import { useIntl, injectIntl } from 'gatsby-plugin-intl';
import SEO from './Seo';

const Redirect = () => {
  const intl = useIntl();
  return (
    <SEO title={intl.formatMessage({ id: 'title' })} />
  );
};

export default injectIntl(Redirect);
