// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-apply-jsx": () => import("../src/pages/apply.jsx" /* webpackChunkName: "component---src-pages-apply-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-contact-us-other-jsx": () => import("../src/pages/contact-us/other.jsx" /* webpackChunkName: "component---src-pages-contact-us-other-jsx" */),
  "component---src-pages-contact-us-students-jsx": () => import("../src/pages/contact-us/students.jsx" /* webpackChunkName: "component---src-pages-contact-us-students-jsx" */),
  "component---src-pages-faq-jsx": () => import("../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kite-booking-jsx": () => import("../src/pages/kite-booking.jsx" /* webpackChunkName: "component---src-pages-kite-booking-jsx" */),
  "component---src-pages-locations-jsx": () => import("../src/pages/locations.jsx" /* webpackChunkName: "component---src-pages-locations-jsx" */),
  "component---src-pages-locations-boston-jsx": () => import("../src/pages/locations/boston.jsx" /* webpackChunkName: "component---src-pages-locations-boston-jsx" */),
  "component---src-pages-locations-brisbane-jsx": () => import("../src/pages/locations/brisbane.jsx" /* webpackChunkName: "component---src-pages-locations-brisbane-jsx" */),
  "component---src-pages-locations-cairns-jsx": () => import("../src/pages/locations/cairns.jsx" /* webpackChunkName: "component---src-pages-locations-cairns-jsx" */),
  "component---src-pages-locations-calgary-jsx": () => import("../src/pages/locations/calgary.jsx" /* webpackChunkName: "component---src-pages-locations-calgary-jsx" */),
  "component---src-pages-locations-dublin-jsx": () => import("../src/pages/locations/dublin.jsx" /* webpackChunkName: "component---src-pages-locations-dublin-jsx" */),
  "component---src-pages-locations-gold-coast-jsx": () => import("../src/pages/locations/gold-coast.jsx" /* webpackChunkName: "component---src-pages-locations-gold-coast-jsx" */),
  "component---src-pages-locations-london-jsx": () => import("../src/pages/locations/london.jsx" /* webpackChunkName: "component---src-pages-locations-london-jsx" */),
  "component---src-pages-locations-melbourne-jsx": () => import("../src/pages/locations/melbourne.jsx" /* webpackChunkName: "component---src-pages-locations-melbourne-jsx" */),
  "component---src-pages-locations-miami-jsx": () => import("../src/pages/locations/miami.jsx" /* webpackChunkName: "component---src-pages-locations-miami-jsx" */),
  "component---src-pages-locations-new-york-jsx": () => import("../src/pages/locations/new-york.jsx" /* webpackChunkName: "component---src-pages-locations-new-york-jsx" */),
  "component---src-pages-locations-oxford-jsx": () => import("../src/pages/locations/oxford.jsx" /* webpackChunkName: "component---src-pages-locations-oxford-jsx" */),
  "component---src-pages-locations-stratford-jsx": () => import("../src/pages/locations/stratford.jsx" /* webpackChunkName: "component---src-pages-locations-stratford-jsx" */),
  "component---src-pages-locations-sydney-jsx": () => import("../src/pages/locations/sydney.jsx" /* webpackChunkName: "component---src-pages-locations-sydney-jsx" */),
  "component---src-pages-locations-toronto-jsx": () => import("../src/pages/locations/toronto.jsx" /* webpackChunkName: "component---src-pages-locations-toronto-jsx" */),
  "component---src-pages-locations-vancouver-jsx": () => import("../src/pages/locations/vancouver.jsx" /* webpackChunkName: "component---src-pages-locations-vancouver-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-program-and-pricing-jsx": () => import("../src/pages/program-and-pricing.jsx" /* webpackChunkName: "component---src-pages-program-and-pricing-jsx" */),
  "component---src-pages-program-and-pricing-agent-referred-jsx": () => import("../src/pages/program-and-pricing/agent-referred.jsx" /* webpackChunkName: "component---src-pages-program-and-pricing-agent-referred-jsx" */),
  "component---src-pages-special-introductory-offer-jsx": () => import("../src/pages/special-introductory-offer.jsx" /* webpackChunkName: "component---src-pages-special-introductory-offer-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */)
}

